import React, { useContext, useEffect } from 'react'
import '../../Main_content/Main_cnt.css'
import { Vahan_context } from '../../Vahan_context';
import { Navigate, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Vahan_batch = () => {

    const { Logout, Batchmasterdata, truncateString, batchid, handlebatchdetails, BatchUpdate, handledatedetails, handelbatchdetedit,
        batchmastersubmit_ac, BatchUpdateeerr, vahanstatus, vahan_data_download, Err_login, Refresh } = useContext(Vahan_context)
    const navigate = useNavigate()
    var Head_img = require('../../Header/Images/MBFSLogo.png');
    useEffect(() => {
        if (batchid === null) {
            navigate('/Vahan_master');
        }
    }, [batchid])
    useEffect(() => {
        const jwt = sessionStorage.getItem('JWT');
        if (!jwt) {
            const errorButton = document.getElementById('Went_wrong');
            if (errorButton) {
                errorButton.click();
            } else {
                console.error("Element with id 'Went_wrong' not found.");
            }
        }
    }, []);

    //console.log(Batchmasterdata)

    return (
        <div className='container-fluid batch_vahan ps-0 pe-0'>



            {/* <input type="text" hidden id='Went_wrong' data-bs-toggle="modal" data-bs-target="#Went_wrong_model" />

            <div className="modal fade" id="Went_wrong_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered center_to">
                    <div className="modal-content model_content">
                        <div className="inp_btn_hide">
                            <div className="row inp_grid">
                                Something Went Wrong.Please Login Again
                            </div>
                            <div className="text-center btn_submit_dv">
                                <button className="grd_btns" onClick={Err_login}>Ok</button>
                                <button data-bs-dismiss="modal" id='err_back' hidden className="grd_btns grey">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <input type="text" hidden id='addition' data-bs-toggle="modal" data-bs-target="#add_batch_det_hit_ac" />
            <input type="text" hidden id='termination' data-bs-toggle="modal" data-bs-target="#add_batch_det_hit_ter" />

            <div className="modal fade" id="add_batch_det_hit_ac" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered center_to">
                    <div className="modal-content model_content batch_view">
                        <div className="inp_btn_hide" id="inp_grid">
                            <div className="row inp_grid">
                                {BatchUpdate?.actionType === 1 && (
                                    <>

                                        <div className="editDiv">
                                            <label>Action Type</label>
                                            <select onChange={handlebatchdetails} name='actionType' value={BatchUpdate.actionType}>
                                                <option value={1}>Addition</option>
                                                <option value={2}>Continuation</option>
                                                <option value={3}>Termination</option>
                                            </select>
                                        </div>
                                        <div className="editDiv">
                                            <label>Chassis Number</label>
                                            <input type="text" className="form__input" name='chasisNumber' onChange={handlebatchdetails} value={BatchUpdate.chasisNumber} />
                                            {BatchUpdateeerr.chasisNumber === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                        <div className="editDiv">
                                            <label>Engine Number</label>
                                            <input type="text" className="form__input" name='engineNumber' onChange={handlebatchdetails} value={BatchUpdate.engineNumber} />
                                            {BatchUpdateeerr.engineNumber === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                        <div className="editDiv">
                                            <label>HPA From</label>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                className="form__input datepicker"
                                                onChange={(date) => handledatedetails(date, 'hpaFrom')}
                                                selected={BatchUpdate.hpaFrom ? new Date(BatchUpdate.hpaFrom) : null}
                                                name='hpaFrom'
                                            />
                                            {BatchUpdateeerr.disHPAFrom === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                        <div className="editDiv">
                                            <label>HPA Upto</label>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                className="form__input datepicker"
                                                onChange={(date) => handledatedetails(date, 'hpaUpto')}
                                                selected={BatchUpdate.hpaUpto ? new Date(BatchUpdate.hpaUpto) : null}
                                                name='hpaUpto'
                                            />
                                            {BatchUpdateeerr.disHPAUpto === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>

                                        <div className="editDiv">
                                            <label>Document URI</label>
                                            <input type="text" className="form__input" name='documentURl' onChange={handlebatchdetails} value={BatchUpdate.documentURl} />
                                            {BatchUpdateeerr.documentURl === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                    </>
                                )
                                }
                                {
                                    BatchUpdate?.actionType === 2 &&

                                    <>

                                        <div className="editDiv">
                                            <label>Action Type</label>
                                            <select onChange={handlebatchdetails} name='actionType' value={BatchUpdate.actionType}>
                                                <option value="1">Addition</option>
                                                <option value="2">Continuation</option>
                                                <option value="3">Termination</option>
                                            </select>
                                        </div>
                                        <div className="editDiv">
                                            <label>Chassis Number</label>
                                            <input type="text" className="form__input" name='chasisNumber' onChange={handlebatchdetails} value={BatchUpdate.chasisNumber} />
                                            {BatchUpdateeerr.chasisNumber === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                        <div className="editDiv">
                                            <label>Engine Number</label>
                                            <input type="text" className="form__input" name='engineNumber' onChange={handlebatchdetails} value={BatchUpdate.engineNumber} />
                                            {BatchUpdateeerr.engineNumber === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                        <div className="editDiv">
                                            <label>HPA From</label>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                className="form__input datepicker"
                                                onChange={(date) => handledatedetails(date, 'hpaFrom')}
                                                selected={BatchUpdate.hpaFrom ? new Date(BatchUpdate.hpaFrom) : null}
                                                name='hpaFrom'
                                            />
                                            {BatchUpdateeerr.disHPAFrom === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                        <div className="editDiv">
                                            <label>HPA Upto</label>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                className="form__input datepicker"
                                                onChange={(date) => handledatedetails(date, 'hpaUpto')}
                                                selected={BatchUpdate.hpaUpto ? new Date(BatchUpdate.hpaUpto) : null}
                                                name='hpaUpto'
                                            />
                                            {BatchUpdateeerr.disHPAUpto === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>

                                        <div className="editDiv">
                                            <label>Document URI</label>
                                            <input type="text" className="form__input" name='documentURl' onChange={handlebatchdetails} value={BatchUpdate.documentURl} />
                                            {BatchUpdateeerr.documentURl === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                    </>

                                }
                                {BatchUpdate?.actionType === 3 &&
                                    <>
                                        <div className="editDiv">
                                            <label>Action Type</label>
                                            <select onChange={handlebatchdetails} name='actionType' value={BatchUpdate.actionType}>
                                                <option value="1">Addition</option>
                                                <option value="2">Continuation</option>
                                                <option value="3">Termination</option>
                                            </select>
                                        </div>
                                        <div className="editDiv">
                                            <label>Chassis Number</label>
                                            <input type="text" className="form__input" name='chasisNumber' onChange={handlebatchdetails} value={BatchUpdate.chasisNumber} />
                                            {BatchUpdateeerr.chasisNumber === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                        <div className="editDiv">
                                            <label>Registration Number</label>
                                            <input type="text" className="form__input" name='registrationNumber' onChange={handlebatchdetails} value={BatchUpdate.registrationNumber} />
                                            {BatchUpdateeerr.registrationNumber === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                        <div className="editDiv">
                                            <label>Termination Date</label>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                className="form__input datepicker"
                                                name='terminationDateTime'
                                                onChange={(date) => handledatedetails(date, 'terminationDateTime')}
                                                selected={BatchUpdate.terminationDateTime ? new Date(BatchUpdate.terminationDateTime) : null}
                                            />
                                            {BatchUpdateeerr.terminationDateTime === true &&
                                                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
                                            }
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="text-center btn_submit_dv">
                                <button className="grd_btns" onClick={batchmastersubmit_ac}>Submit</button>
                                <button data-bs-dismiss="modal" id='batch_ac_model_cls' className="grd_btns grey">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="modal fade" id="add_batch_det_hit_ter" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered center_to">
                    <div className="modal-content model_content batch_view">
                        <div className="inp_btn_hide" id="inp_grid">
                            <div className="row inp_grid">

                              

                            </div>
                            <div className="text-center btn_submit_dv">
                                <button className="grd_btns" onClick={batchmastersubmit_Ter}>Submit</button>
                                <button data-bs-dismiss="modal" id='batch_ter_mdl' className="grd_btns grey">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="header_top tw">
                <div className="header_left_sd">
                    <div className="mbfs_image">
                        <img src={Head_img} alt="MBFS_LOGO" />
                    </div>
                </div>
                <div className="end_logout_btn">
                    <button onClick={Logout}>
                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                        <span>Log Out</span>
                    </button>
                </div>
            </div>
            <div className='container batch_container'>
                <div className="grd_buttons_dv">
                    <i onClick={() => navigate('/Vahan_master')} className="fa-solid fa-circle-arrow-left cir"></i>
                    {
                        vahanstatus && vahanstatus.vahanst === 2 ? (
                            <button onClick={() => vahan_data_download(vahanstatus.batchid)} className="grd_btns">Download</button>
                        ) : (
                            <button onClick={Refresh} className="grd_btns">Refresh</button>
                        )
                    }
                </div>
                <div className="batch_no">
                    <label>Batch No : </label>
                    <span style={{ paddingLeft: '5px' }}>{Batchmasterdata?.[0]?.batchID}</span>
                </div>
                <div>
                    <table className='table table_dv'>
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th>Action Type</th>
                                <th>Chassis Number</th>
                                <th>Engine Number</th>
                                <th style={{ width: '100px' }}>HPA From</th>
                                <th style={{ width: '100px' }}>HPA Upto</th>
                                <th>Document URL</th>
                                <th>Registration Number</th>
                                <th style={{ width: '120px' }}>Termination Date</th>
                                <th>Status</th>
                                <th>Vahan Message</th>
                                <th>Vahan Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Batchmasterdata && Batchmasterdata.map((batch, id) => (
                                <tr key={id}>
                                    <td className="action_font"><i onClick={() => handelbatchdetedit(batch.actionType, batch.chasisNumber, batch.engineNumber, batch.hpaFrom, batch.hpaUpto, batch.documentURl, batch.registrationNumber,
                                        batch.terminationDateTime, batch.batchID, batch.vahanId)} className="fa-solid fa-pen-to-square"></i></td>
                                    <td>{batch.actionType === 1 ? (
                                        'Addition'
                                    ) : batch.actionType === 2 ? (
                                        'Continuation'
                                    ) : batch.actionType === 3 ? (
                                        'Termination'
                                    ) : (
                                        ''
                                    )}</td>
                                    <td>{batch.chasisNumber}</td>
                                    <td>{batch.engineNumber}</td>
                                    <td>{batch.hpaFrom}</td>
                                    <td>{batch.hpaUpto}</td>
                                    <td>{batch.documentURl}</td>
                                    <td>{batch.registrationNumber}</td>
                                    <td>{batch.terminationDateTime}</td>
                                    <td>{batch.status === 0 ? (
                                        <span className='back_grnd pend_txt'>Pending</span>
                                    ) : batch.status === 1 ? (
                                        <span className='back_grnd in_pro_txt'>In Progress</span>
                                    ) : batch.status === 2 ? (
                                        <span className='back_grnd com_txt'>Completed</span>
                                    ) : (
                                        <span className='back_grnd err_va'>Error</span>
                                    )}</td>
                                    <td className={batch.vahanResponseMessage ? 'tooltip tool_tip' : ""} style={{ cursor: 'pointer' }} data-tooltip={batch.vahanResponseMessage ? batch.vahanResponseMessage : ""}>
                                        {batch.vahanResponseMessage ? truncateString(batch.vahanResponseMessage) : ""}
                                    </td>
                                    <td>
                                        {batch.vahanResponseCode != null ? (
                                            batch.vahanResponseCode === "1" ? (
                                                <span className="success_txt back_grnd">Success</span>
                                            ) : (
                                                <span className="failed_txt back_grnd">Failed</span>
                                            )
                                        ) : ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Vahan_batch