import axios from "axios";
import { createContext, useEffect, useRef, useState } from "react";
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";

const Vahan_context = createContext();

const ContextProvider = ({ children }) => {

  //AuthService

  const baseURL = process.env.React_App_baseURL;
  //const baseURL = "https://apps-mbfsin-dev-vahanapi.azurewebsites.net/"
  let Currenttoken = "";

  const navigate = useNavigate()

  const PostServiceCallToken = (apiName, postData) => {
    Currenttoken = decryptData('JWT');

    return axios
      .post(baseURL + apiName, postData, { headers: { "Authorization": `Bearer ${Currenttoken}` } })
      .then((response) => {
        if (response && response !== undefined && response !== null && response.data) {
          return response.data
        }
        else {
          document.getElementById('Went_wrong').click();
        }
      }).catch(err => {
        document.getElementById('Went_wrong').click();
      });
  };


  const GetServiceCall = (apiName) => {
    Currenttoken = decryptData('JWT');
    return axios
      .get(baseURL + apiName, { headers: { "Authorization": `Bearer ${Currenttoken}` } })
      .then((response) => {
        if (response && response !== undefined && response !== null && response.data) {
          return response.data
        }
        else {
          document.getElementById('Went_wrong').click();
        }

      }).catch(err => {
        document.getElementById('Went_wrong').click();

      });
  };

  const PostServiceCall = (apiName, postData) => {
    return axios
      .post(baseURL + apiName, postData)
      .then((response) => {
        if (response && response !== undefined && response !== null && response.data) {
          return response.data
        }
        else {
          document.getElementById('Went_wrong').click();
        }

      }).catch(err => {
        document.getElementById('Went_wrong').click();
        let apierror = err.message
        if (err.response.data.errors) {
          apierror = err.response.data.errors[0].msg
        } else if (err.response.data.msg) {
          apierror = err.response.data.msg
        }
        return apierror
      });
  };

  const encryptData = (name, data) => {
    sessionStorage.setItem(name, data);
  };

  const decryptData = (name) => {
    const ciphertext = sessionStorage.getItem(name);
    return ciphertext;
  };


  const decodeJWT = (token) => {
    if (token) {
      let tokenParts = token.split('.');
      let payload = JSON.parse(atob(tokenParts[1]));
      return payload;
    }
  }
  const decodedPayload = decodeJWT(decryptData('JWT'));
  // console.log(decodedPayload)


  useEffect(() => {
    if (decodedPayload) {
      const sessionstarttime = decodedPayload.exp ? new Date(decodedPayload.exp * 1000) : new Date();
      const sessionenddate = new Date(sessionstarttime.setMinutes(sessionstarttime.getMinutes() - 5));
      // console.log(sessionenddate);
      // console.log(new Date())
      if (new Date() >= sessionenddate) {
        document.getElementById('Went_wrong').click();
      }
    }
  }, [decodedPayload])


  //Login_function 

  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [loginErr, setLoginErr] = useState({ usernameErr: '', userPassword: '', loginerr: false });

  const Login_vahan = () => {
    if (username === '') {
      setLoginErr(prevState => ({
        ...prevState,
        usernameErr: 'Please Provide Valid Name'
      }));
    } else {
      setLoginErr(prevState => ({
        ...prevState,
        usernameErr: ''
      }));
    }
    if (password === '') {
      setLoginErr(prevState => ({
        ...prevState,
        userPassword: 'Please Provide Valid Password'
      }));
      return false
    } else {
      setLoginErr(prevState => ({
        ...prevState,
        userPassword: ''
      }));
    }
    const Login_obj = {
      "userName": username,
      "userPassword": password
    }
    if (username !== "" && password !== "") {
      PostServiceCall('api/Login', Login_obj)
        .then((res) => {
          if (res && res !== undefined && res !== null) {
            // console.log(res)
            encryptData('useridentifier', res.identifier)
            if (res.isAuthorized === true) {
              setLoginErr(prevState => ({
                ...prevState,
                loginerr: false
              }));
              encryptData('Usertype', res.userType)
              encryptData('JWT', res.authToken)
              navigate('/Vahan_master')
            } else {
              setLoginErr(prevState => ({
                ...prevState,
                loginerr: true
              }));

            }
          }
        }).catch((err) => console.log("Error:", err))
    }

  };

  const GetLoginDetails = (code) => {
    // console.log(code, 'method')
    GetServiceCall('api/Login/SSOLogin?code=' + code)
      .then((res) => {
        if (res && res !== undefined && res !== null) {
          // console.log(res)
          encryptData('useridentifier', res.identifier)
          if (res.isAuthorized === true) {
            setLoginErr(prevState => ({
              ...prevState,
              loginerr: false
            }));
            encryptData('Usertype', res.userType)
            encryptData('JWT', res.authToken)
            navigate('/Vahan_master')
          } else {
            setLoginErr(prevState => ({
              ...prevState,
              loginerr: true
            }));

          }
        }
      }).catch((err) => {
        // console.log("Error:", err);
        navigate('/unauthorized')
      });
  };

  const Logout = () => {
    // setusername('');
    // setpassword('');
    // sessionStorage.clear();
    // navigate('/');
    console.log('coming')
    GetServiceCall('api/Login/SSOLogOutURL')
      .then((res) => {
        console.log(res)
        if (res && res !== undefined && res !== null) {
          window.location.href = res.ssourl;
        }
      })
      .catch((err) => console.log('Error:', err));
  };

  //Usermaster

  const [Usermasterdata, Setusermasterdata] = useState([])
  const [Usermasterdatacount, Setusermasterdatacount] = useState()
  const [UserPaginations, SetuserPaginations] = useState({ Current_page: 1 });
  const [UserUpdate, setUserUpdate] = useState({ userName: '', emailId: '', department: '', userStatus: '2', userType: 2, password: '', identifier: '' })
  const [UserUpdateerr, setUserUpdateerr] = useState({ userName: false, emailId: false, department: false, password: false })
  const [Userdelete, Setuserdelete] = useState('')
  const Usermaster = {
    startPage: UserPaginations.Current_page,
    noofPages: 0,
    sortType: true,
    sortColumn: "",
    search: ""
  }

  const GetUsermasterdata = () => {
    PostServiceCallToken('api/User/GetUserDetails', Usermaster)
      .then((res) => {
        if (res) {
          Setusermasterdata(res.data)
          Setusermasterdatacount(res.recordCount)
        } else {
          Setusermasterdata([])
        }
      })
  }
  const UserpageNumbers = [];
  for (let i = 1; i <= Math.ceil(Usermasterdatacount && Usermasterdatacount / 10); i++) {
    UserpageNumbers.push(i);
  }
  const UserhandlePageChange = (pageNumber) => {
    SetuserPaginations({ ...UserPaginations, Current_page: UserPaginations.Current_page = pageNumber });
  };

  const handle_view_change = (e) => {
    const { name, value } = e.target
    setUserUpdate({ ...UserUpdate, [name]: value })
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const Userdetailssubmit = () => {
    const isUserNameValid = UserUpdate.userName !== '';
    setUserUpdateerr(state => ({ ...state, userName: !isUserNameValid }));

    const isEmailValid = UserUpdate.emailId !== '' && validateEmail(UserUpdate.emailId);
    setUserUpdateerr(state => ({ ...state, emailId: !isEmailValid }));

    const isDepartmentValid = UserUpdate.department !== '';
    setUserUpdateerr(state => ({ ...state, department: !isDepartmentValid }));

    // const isPasswordValid = UserUpdate.password !== '';
    // setUserUpdateerr(state => ({ ...state, password: !isPasswordValid }));

    if (isUserNameValid && isEmailValid && isDepartmentValid) {
      PostServiceCallToken('api/User/InsertUpdateData', UserUpdate)
        .then((res) => {
          if (UserUpdate.identifier === "") {
            SetuserPaginations({ ...UserPaginations, Current_page: UserPaginations.Current_page = 1 });
            GetUsermasterdata();
          }
          setUserUpdate(state => ({
            ...state, userName: '', emailId: '', department: '', userStatus: '2', userType: 2, password: '', identifier: ''
          }))
          document.getElementById('model_cancel').click();
          setUserUpdateerr(prev => ({ ...prev, password: false, department: false, emailId: false, userName: false }))
          GetUsermasterdata();
        })
    }
  }


  const Editusermaster = (userName, emailId, department, userStatus, userType, password, identifier) => {
    setUserUpdate(state => ({
      ...state, userName: userName, emailId: emailId, department: department, userStatus: userStatus, userType: userType, password: password, identifier: identifier
    }))
    document.getElementById('add_userdata').click();
  }

  const Userdeltemodel = (identifier) => {
    Setuserdelete(identifier)
    document.getElementById('delete_model').click();
  }
  const UserDelete = () => {
    PostServiceCallToken(`api/User/DeleteData?identifier=${Userdelete}`)
      .then((res) => {
        GetUsermasterdata();
        Setuserdelete('')
      })
  }

  const cancelusersumbit = () => {
    setUserUpdate(state => ({
      ...state, userName: '', emailId: '', department: '', password: '', identifier: ''
    }))
    setUserUpdateerr(prev => ({ ...prev, password: false, department: false, emailId: false, userName: false }))
  }

  //Vahan_master
  const [Vahanmasterdata, SetVahanmasterdata] = useState([])
  const [Vahanmasterdatacount, SetVahanmasterdatacount] = useState()
  const [VahanPaginations, SetVahanPaginations] = useState({ Current_page: 1 });

  const Vahan = {
    batchId: 0,
    pageno: VahanPaginations.Current_page,
    pagesize: 0
  }

  const GetVahanMasterdetails = () => {
    PostServiceCallToken('api/Vahan/GetBatchDetails', Vahan)
      .then((res) => {
        if (res && res.data) {
          if (res.data.length > 0) {
            SetVahanmasterdata(res.data)
            SetVahanmasterdatacount(res.recordCount)
          } else {
            SetVahanmasterdata([])
          }
        } else {
          SetVahanmasterdata([])
        }
      })
  }

  const vahanpageNumbers = [];
  for (let i = 1; i <= Math.ceil(Vahanmasterdatacount && Vahanmasterdatacount / 10); i++) {
    UserpageNumbers.push(i);
  }
  const vahanhandlePageChange = (pageNumber) => {
    SetVahanPaginations({ ...VahanPaginations, Current_page: VahanPaginations.Current_page = pageNumber });
  };
  const Download_Vahan_template = () => {
    GetServiceCall('api/Vahan/DownloadVahanTemplate')
      .then((res) => {
        // console.log(res)
        if (res && res !== '' && res.responseCode === "200") {
          const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.base64String}`;
          const link = document.createElement('a');
          link.href = href;
          link.download = res.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
        }
      })
  }
  let batchInitialData = { batchno: '', batchdate: '', batchfilename: '', userIdentifier: '', addbatcherrr: '', filename: '', spinnerforupload: false };
  const [addbatchdet, setaddbatchdet] = useState(batchInitialData)
  let fileInputRef = useRef(null);
 
  const GetBatchNo = () => {
    GetServiceCall('api/Vahan/GetBatchNo')
      .then((res => {
        // console.log(res)
        if (res && res !== '' && res.status === "success") {
          const extbatchdate = res.responseTime.split(" ");
          const extdate = extbatchdate[0];
          // console.log(extdate)
          const date = new Date(extdate);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          // console.log(formattedDate)
          setaddbatchdet(state => ({ ...state, batchno: res.data, batchdate: formattedDate }));
          document.getElementById('add_batch_mdl').click();
        }
      }))
  }
  const handleFileChange = (e) => {
    const File = e.target.files[0];
    // console.log('Selected file:', File);
    const fd = new FormData();
    fd.append('file', File)
    if (File != null) {
      setaddbatchdet(state => ({ ...state, batchfilename: File.name }));
    }
    console.log(fd)
    const myfilename = File.name
    const ext = myfilename.split('.').pop();
    if (ext === 'xlsx' || ext === 'xls') {
      PostServiceCallToken('api/Vahan/UploadFile', fd)
        .then((res) => {
          // console.log(res)
          setaddbatchdet(state => ({ ...state, filename: res }));
          e.target.value = null;
        })
    }
  }
  const handleuploadclick = () => {
    fileInputRef.current.click();
  }
  const batch_upload_submit = () => {
    setaddbatchdet(state => ({ ...state, spinnerforupload: true }));
    PostServiceCallToken('api/Vahan/InsertBatchData', {
      ...addbatchdet,
      userIdentifier: decryptData('useridentifier')
    })
      .then((res) => {
        console.log(res)
        if (res.errorMsg !== "") {
          setaddbatchdet(state => ({ ...state, spinnerforupload: false, addbatcherrr: res.errorMsg }));
          // setaddbatchdet(state => ({ ...state, }));
        } else {
          GetVahanMasterdetails();
          const uploadCancelBtn = document.getElementById('upload_cancel_inp');
          if (uploadCancelBtn) {
            uploadCancelBtn.click();
          }
          setaddbatchdet(state => ({ ...state, spinnerforupload: false }));
          upload_cancel();
        }
      })
  }
  const [spinner, setSpinner] = useState('');
  const vahan_data_download = (batchid) => {
    setSpinner(batchid);
    // spinnerref.current = true
    GetServiceCall(`api/Vahan/DownloadVahanToExcel?batchid=${batchid}`)
      .then((res) => {
        // console.log('coming')
        // console.log(res)

        if (res && res !== '' && res.responseCode === "200") {
          setSpinner('');
          // alert('coming')
          // console.log('ulla coming')
          const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.base64String}`;
          const link = document.createElement('a');
          link.href = href;
          link.download = res.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
        } else {
          setSpinner('');
          document.getElementById('recordno').click();
        }
      })
  }
  const [vahandeleet, setvahandelete] = useState()

  const delete_vahan_fun = () => {
    // console.log(vahandeleet)
    PostServiceCallToken(`api/Vahan/DeleteBatch?batchid=${vahandeleet}`)
      .then((res) => {
        // console.log(res)
        if (res) {
          document.getElementById('delete_dismiss').click();
          GetVahanMasterdetails();
        }

      })
  }

  const upload_cancel = () => {
    setaddbatchdet(batchInitialData)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; 
    }
  }
  //Batch_master

  const [Batchmasterdata, SetBatchmasterdata] = useState([])
  const [batchid, setbatchid] = useState(null)
  const [BatchUpdate, setBatchUpdate] = useState({
    actionType: Number("1"), chasisNumber: '', engineNumber: '', hpaFrom: new Date(), hpaUpto: new Date(),
    documentURl: '', registrationNumber: '', terminationDateTime: new Date(), batchID: 0, vahanId: 0
  })
  const [BatchUpdateeerr, setBatchUpdateerr] = useState({
    chasisNumber: false, engineNumber: false, disHPAFrom: false,
    disHPAUpto: false, documentURl: false, registrationNumber: false, terminationDateTime: false
  })
  const [vahanstatus, setvahanstatus] = useState({ vahanst: 0, batchid: 0 })
  const Getbatchmasterdata = (arg, status) => {
    setbatchid(arg)
    PostServiceCallToken('api/Vahan/GetBatchDetails', { batchId: arg, pageno: 0, pagesize: 0 })
      .then((res) => {
        // console.log(res)
        // console.log(status)
        setvahanstatus(state => ({
          ...state, vahanst: status, batchid: arg
        }))
        if (res && res !== '' && res.status === "success") {
          SetBatchmasterdata(res.data)
        } else {
          SetBatchmasterdata([])
        }

        navigate('/Vahan_batch')
      })
  }
  const Refresh = () => {
    Getbatchmasterdata(batchid);
  }
  const truncateString = (str) => {
    if (str.length > 10) {
      return str.slice(0, 10) + "...";
    } else if (str.length === 0) {
      return 'Empty';
    } else {
      return str;
    }
  };


  const handlebatchdetails = (e) => {
    const { name, value } = e.target;
    if (name === 'actionType') {
      setBatchUpdate({ ...BatchUpdate, [name]: Number(value) });
    } else {
      setBatchUpdate({ ...BatchUpdate, [name]: value });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handledatedetails = (date, fieldName) => {
    setBatchUpdate(prevState => ({
      ...prevState,
      [fieldName]: date
    }));
  };



  const handelbatchdetedit = (actionType, chasisNumber, engineNumber, hpaFro, hpaUpt, documentURl, registrationNumber, terminationDateTime, batchID, vahanId) => {

    const parseDateString = (dateString) => {
      const [day, month, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
    };

    const hpaFromdate = hpaFro ? new Date(parseDateString(hpaFro)) : null;
    const hpaUptodate = hpaUpt ? new Date(parseDateString(hpaUpt)) : null;
    const terminationDateTimedate = terminationDateTime ? new Date(parseDateString(terminationDateTime)) : null;

    setBatchUpdate(prevState => ({
      ...prevState,
      actionType: Number(actionType),
      chasisNumber: chasisNumber,
      engineNumber: engineNumber,
      hpaFrom: hpaFromdate,
      hpaUpto: hpaUptodate,
      documentURl: documentURl,
      registrationNumber: registrationNumber,
      terminationDateTime: terminationDateTimedate,
      batchID: batchID,
      vahanId: vahanId

    }));
    // if (actionType === 1 || actionType === 2) {
    //   document.getElementById('addition').click();
    // } else if (actionType === 3) {
    //   document.getElementById('termination').click();
    // }

    document.getElementById('addition').click();
  };



  const batchmastersubmit_ac = () => {

    if (BatchUpdate.actionType === 1 || BatchUpdate.actionType === 2) {

      const chasisNumbervalid = BatchUpdate.chasisNumber === null || BatchUpdate.chasisNumber === '';
      setBatchUpdateerr(state => ({ ...state, chasisNumber: chasisNumbervalid }));

      const engineNumbervalid = BatchUpdate.engineNumber === null || BatchUpdate.engineNumber === '';
      setBatchUpdateerr(state => ({ ...state, engineNumber: engineNumbervalid }));

      const disHPAFromvalid = BatchUpdate.hpaFrom === null || BatchUpdate.hpaFrom === '';
      setBatchUpdateerr(state => ({ ...state, disHPAFrom: disHPAFromvalid }));

      const disHPAUptovalid = BatchUpdate.hpaUpto === null || BatchUpdate.hpaUpto === '';
      setBatchUpdateerr(state => ({ ...state, disHPAUpto: disHPAUptovalid }));

      const documentURlvalid = BatchUpdate.documentURl === null || BatchUpdate.documentURl === '';
      setBatchUpdateerr(state => ({ ...state, documentURl: documentURlvalid }));

      if (!chasisNumbervalid && !engineNumbervalid && !disHPAFromvalid && !disHPAUptovalid && !documentURlvalid) {
        const formattedHPAFromDate = formatDate(BatchUpdate.hpaFrom);
        const formattedHPAUptoDate = formatDate(BatchUpdate.hpaUpto);
        const formattedterminationDate = formatDate(BatchUpdate.terminationDateTime);

        PostServiceCallToken('api/Vahan/UpdateVahanDetails', {
          ...BatchUpdate,
          hpaFrom: formattedHPAFromDate,
          hpaUpto: formattedHPAUptoDate,
          terminationDateTime: formattedterminationDate
        })
          .then((res) => {
            // console.log(res)
            Getbatchmasterdata(batchid)
            document.getElementById('batch_ac_model_cls').click();
          })
      }
    } else {
      const chasisNumbervalid = BatchUpdate.chasisNumber === null || BatchUpdate.chasisNumber === '';
      setBatchUpdateerr(state => ({ ...state, chasisNumber: chasisNumbervalid }));

      const registrationNumbervalid = BatchUpdate.registrationNumber === null || BatchUpdate.registrationNumber === '';
      setBatchUpdateerr(state => ({ ...state, registrationNumber: registrationNumbervalid }));

      const terminationDateTimevalid = BatchUpdate.terminationDateTime === null || BatchUpdate.terminationDateTime === '';
      setBatchUpdateerr(state => ({ ...state, terminationDateTime: terminationDateTimevalid }));



      if (!chasisNumbervalid && !registrationNumbervalid && !terminationDateTimevalid) {

        const formattedterminationDate = formatDate(BatchUpdate.terminationDateTime);

        PostServiceCallToken('api/Vahan/UpdateVahanDetails', {
          ...BatchUpdate,
          terminationDateTime: formattedterminationDate
        })
          .then((res) => {
            // console.log(res)
            Getbatchmasterdata(batchid)
            document.getElementById('batch_ac_model_cls').click();
          })
      }

    }

  }

  const Err_login = () => {
    navigate('/');
    sessionStorage.clear();
    document.getElementById('err_back').click();
  }

  const user_master_btn = () => {
    Usermaster.startPage = 1
    SetuserPaginations({ ...UserPaginations, Current_page: UserPaginations.Current_page = 1 });
    GetUsermasterdata();
  }

  const vahan_master_btn = () => {
    Vahan.pageno = 1
    SetVahanPaginations({ ...VahanPaginations, Current_page: VahanPaginations.Current_page = 1 });
    GetVahanMasterdetails();
  }
  return (
    <Vahan_context.Provider value={{
      Login_vahan, setusername, setpassword, loginErr, password, username, Logout, decryptData, Usermasterdata, UserUpdate, Userdetailssubmit, Userdeltemodel,
      UserpageNumbers, UserPaginations, UserhandlePageChange, Usermasterdatacount, GetUsermasterdata, handle_view_change, UserUpdateerr, Editusermaster, UserDelete,
      VahanPaginations, Vahanmasterdatacount, Vahanmasterdata, vahanpageNumbers, vahanhandlePageChange, GetVahanMasterdetails, Download_Vahan_template, 
      GetBatchNo, addbatchdet, handleFileChange, fileInputRef, handleuploadclick, Getbatchmasterdata, Batchmasterdata, truncateString, batchid, handlebatchdetails,
      BatchUpdate, handledatedetails, handelbatchdetedit, batchmastersubmit_ac, vahanstatus, BatchUpdateeerr, addbatchdet, batch_upload_submit, vahan_data_download,
      setvahandelete, delete_vahan_fun, Err_login, cancelusersumbit, user_master_btn, vahan_master_btn, upload_cancel, Refresh, GetLoginDetails, GetServiceCall, spinner
    }}>
      {children}
    </Vahan_context.Provider>
  )
}

export { ContextProvider, Vahan_context };